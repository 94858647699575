<template>
  <form class="login-form" @submit.prevent="onSubmit()">
    <dx-form :form-data="formData" :disabled="loading">
      <dx-item
        data-field="userId"
        editor-type="dxTextBox"
        :editor-options="{ stylingMode: 'filled', placeholder: 'ID', inputAttr: {'style': 'text-transform: uppercase'} }"
      >
        <dx-required-rule message="ID 필수 입력" />
        
        <dx-label :visible="false" />
      </dx-item>
      <dx-item
        data-field='userPassword'
        editor-type='dxTextBox'
        :editor-options="{ stylingMode: 'filled', placeholder: 'Password', mode: 'password' }"
      >
        <dx-required-rule message="비밀번호 필수 입력" />
        <dx-label :visible="false" />
      </dx-item>

      <dx-item
        data-field='system'
        editor-type='dxSelectBox'
        :editor-options="{items: ['MES']}"
        >
      </dx-item>

      <dx-button-item>
        <dx-button-options
          width="100%"
          type="default"
          template="signInTemplate"
          :use-submit-behavior="true"
        >
        </dx-button-options>
      </dx-button-item>

      <template #signInTemplate>
        <div>
          <span class="dx-button-text">
            <dx-load-indicator v-if="loading" width="24px" height="24px" :visible="true" />
            <span v-if="!loading">LOG IN</span>
          </span>
        </div>
      </template>
    </dx-form>
  </form>
</template>

<script>
import auth from "../auth";

import DxLoadIndicator from "devextreme-vue/load-indicator";
import DxForm, {
  DxItem,
  DxRequiredRule,
  DxLabel,
  DxButtonItem,
  DxButtonOptions
} from "devextreme-vue/form";

export default {
  data() {
    return {
      formData: {system: 'MES'},
      loading: false
    };
  },
  methods: {
     onSubmit: async function() {
      this.loading = true;
      this.formData.userId = this.formData.userId.toUpperCase();
      await auth.login(this.formData)
                .then( result => {
                                    if(result) {
                                        this.$router.push("/" + this.formData.system + "/home");
                                    }
                                    this.loading = false;
                                  }
                      );
     }
  },
  components: {
    DxLoadIndicator,
    DxForm,
    DxRequiredRule,
    DxItem,
    DxLabel,
    DxButtonItem,
    DxButtonOptions
  }
}
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";

.login-form {
  .link {
    text-align: center;
    font-size: 16px;
    font-style: normal;

    a {
      text-decoration: none;
    }
  }

  .form-text {
    margin: 10px 0;
    color: rgba($base-text-color, alpha($base-text-color) * 0.7);
  }

}
</style>
